<!-- 选择客户和对账周期 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'选择客户和对账周期':'选择客户和对账周期'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <el-form-item label="客户">
          <!--<el-button @click="Tocomplaint">请选择客户</el-button>-->
          <el-select
            style="width: 220px !important;"
            clearable
            v-model="form.channel_id"
            placeholder="请选择"
            class="ele-fluid">
            <el-option v-for="(item) in client" :label="item.channel_name" :value="parseInt(item.id)"/>
          </el-select>
        </el-form-item>

        <el-form-item label="对账开始日期">
          <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.start_time"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="对账结束日期">
          <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.end_time"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>


      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>

    <!--<el-dialog-->
    <!--    width="40%"-->
    <!--    center-->
    <!--    :visible="complaint"-->
    <!--    :lock-scroll="false"-->
    <!--    :destroy-on-close="true"-->
    <!--    custom-class="ele-dialog-form"-->
    <!--    title="选择客户"-->
    <!--    :before-close="handleClose">-->
    <!--  <el-form-->
    <!--      ref="form"-->
    <!--      :model="form"-->
    <!--      label-width="100px">-->

    <!--    <el-form-item label="客户名称" label-width="100px">-->
    <!--      <el-input-->
    <!--          :maxlength="20"-->
    <!--          v-model="form.name"-->
    <!--          placeholder="请输入客户名称"-->
    <!--          clearable/>-->
    <!--    </el-form-item>-->

    <!--    <el-table-->
    <!--        align="center"-->
    <!--        border-->
    <!--        height="calc(100vh - 261px)"-->
    <!--        ref="Table"-->
    <!--        :data="DictList"-->
    <!--        tooltip-effect="dark"-->
    <!--        style="width: 100%"-->
    <!--        row-key="id"-->
    <!--        :headerCellStyle="headerCellStyle"-->
    <!--        @select="selectDict"-->
    <!--        @selection-change="handleSelectionChange">-->
    <!--      <el-table-column-->
    <!--          type="selection"-->
    <!--          width="55">-->
    <!--      </el-table-column>-->
    <!--      <el-table-column-->
    <!--          label="客户名称"-->
    <!--          prop="name">-->
    <!--      </el-table-column>-->
    <!--      <el-table-column-->
    <!--          label="客户地址"-->
    <!--          prop="address">-->
    <!--      </el-table-column>-->
    <!--    </el-table>-->

    <!--  </el-form>-->


    <!--  <div slot="footer">-->
    <!--    <el-button-->
    <!--        @click="quxiao()">取消-->
    <!--    </el-button>-->
    <!--    <el-button-->
    <!--        style="margin-left: 20px;"-->
    <!--        type="primary"-->
    <!--        :loading="loading"-->
    <!--        @click="quxiao">确定-->
    <!--    </el-button>-->
    <!--  </div>-->
    <!--</el-dialog>-->

  </div>


</template>

<script>
import {get_select} from "@/api/custom";
import {savereconciliations} from "@/api/finance";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //选择服务商
      complaint:false,

      // 表格数据
      DictList: [],
      // 总条目数
      Dicttotal: 0,
      //加载动画
      Dictloading:true,
      current:null,

      //客户列表
      client:[],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    // 调用获取客户列表
    this.getKehu();
  },
  methods: {
    //获取客户名称列表
    getKehu(){
      get_select().then(res => {
        this.client = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.form.channel_id == undefined){
            this.$message.error('请选择客户');
          }else if(this.form.start_time == undefined){
            this.$message.error('请选择对账开始日期');
          }else if(this.form.end_time == undefined){
            this.$message.error('请选择对账结束日期');
          }else {
            let data = {
              channel_id : this.form.channel_id,
              channel_name: this.form.channel_name,
              start_time: this.form.start_time,
              end_time: this.form.end_time
            }
            savereconciliations(data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择客户
    Tocomplaint(){
      this.complaint = true;
    },
    handleClose(){
      this.complaint = false;
      this.updateVisible(true);
    },


    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },

    //选择板块
    // selectDict(selection, row){
    //   console.log(selection)
    //   console.log("选择的所有数据行", selection)
    //   console.log("当前选择的数据行", row)
    //   if(selection.length === 0){
    //     this.current = null;
    //   }else {
    //     this.current = row;
    //   }
    //   console.log(this.current);
    // },
    // //实现单选
    // handleSelectionChange(val){
    //   console.log(val)
    //   console.log(this.$refs.Table)
    //   console.log("selection rows:", val);
    //   this.$nextTick(() => {
    //     if (val.length > 1) {
    //       this.$refs.Table.clearSelection()
    //       this.$refs.Table.toggleRowSelection(val.pop())
    //     }
    //   })
    // },
    //
    // quxiao(){
    //   this.complaint = false;
    //   this.updateVisible(true);
    // },


  }
}
</script>

<style scoped lang="scss">
.no-multiple {
  // 隐藏多选
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
}
</style>
